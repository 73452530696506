.header-menu,
.ant-menu-item,
.ant-menu-submenu{
  a {
    color: black;
  
    &:focus,
    &:hover,
    &:active{
      text-decoration: none;
    }
  }
}

.sidebar-menu a {
    color: #D3D4D9 !important;
}

.sidebar-menu-sp a {
    color: #1A1A1A !important;
}


.sidebar-menu svg {
    color: #D3D4D9 !important;
    font-size: 16px;
    font-weight: bold;
}

.sidebar-menu-sp svg {
    color: #1A1A1A !important;
    font-size: 16px;
    font-weight: bold;
}

.sidebar-logo {
    padding-top: 20px;
    padding-left: 32px;
    padding-bottom: 19px;
    cursor: pointer;
    /* width: 100px; */
}

.public-sider-style {
    background-color: #0E181D;
    border-right: 1px solid #1D3B49;
 }